// Import everything from autoload folder
import './autoload/_add-article.js'; import './autoload/_add-edition.js'; import './autoload/_archive-user.js'; import './autoload/_bootstrap.js'; import './autoload/_edition.js'; import './autoload/_register-user.js'; import './autoload/_users-grid.js'; import './autoload/_workspace.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// import react habitat and react components
import './react-init'; // eslint-disable-line

import 'paginationjs';

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import { Fancybox } from '@fancyapps/ui';

const pageNavHeight = () => {
  let root = document.documentElement;
  const headerHeight = $('.header').outerHeight();
  let totalHeight = headerHeight;
  if ($('.page_top').length) {
    totalHeight = headerHeight + $('.page_top').outerHeight();
  }
  root.style.setProperty('--page-nav-height', totalHeight + 'px');
};

// global ajax_object
//let imagesArray = []; // Selected images
// function addToArray(currentImages) {
//   if (currentImages) {
//     imagesArray = currentImages;
//   }
//   $(document)
//     .find('.js-add-image-page')
//     .on('click', function () {
//       let pageNumber = $(this).val();
//       let idx = imagesArray.indexOf(pageNumber);
//       if (idx >= 0) {
//         imagesArray.splice(idx, 1); //Remove selected images if already chosen
//       } else {
//         imagesArray.push(pageNumber); //Add new image to array
//       }
//       if (imagesArray.length) {
//         $('.next-pdf-step').removeClass('hidden');
//       } else {
//         $('.next-pdf-step').addClass('hidden');
//       }
//     });
// }

// const imagesPaginationAjax = (page = 1, folder_name, imagesArray) => {
//   const formData = new FormData();
//   formData.append('action', 'image_page');
//   formData.append('page', page);
//   formData.append('folder_name', folder_name);
//   formData.append('selected_images', imagesArray);
//
//   const options = {
//     method: 'POST',
//     body: formData,
//   };
//
//   fetch(ajax_object.ajax_url, options)
//     .then((response) => response.json())
//     .then((data) => {
//       $('.images-grid').html(data.html);
//     });
// };
//let firstLoad = true;
// function imagesPagination(count, folder_name, imagesArray) {
//   $(document)
//     .find('#images-pagination')
//     .pagination({
//       dataSource: function (done) {
//         let result = [];
//         for (let i = 0; i < count; i++) {
//           result.push(i);
//         }
//         done(result);
//       },
//       className: 'pdf-pagination',
//       prevText: 'Prev 4',
//       nextClassName: 'pdf-list-nav',
//       prevClassName: 'pdf-list-nav',
//       pageSize: 4,
//       showPageNumbers: false,
//       afterPaging: function (page) {
//         if (!firstLoad) {
//           imagesPaginationAjax(page, folder_name, imagesArray);
//           setTimeout(function () {
//             addToArray(imagesArray);
//           }, 300);
//         }
//         firstLoad = false;
//       },
//       callback: function (data, pagin) {
//         let currentPage = pagin.pageNumber;
//         let nextBtnNumber = 4;
//         if (currentPage === 1) {
//           nextBtnNumber = count - 4 > 4 ? 4 : count - 4;
//         } else {
//           if (count - currentPage * 4 < 4) {
//             nextBtnNumber = count - currentPage * 4;
//           }
//         }
//         pagin.el.find('.J-paginationjs-next').text('Next ' + nextBtnNumber);
//       },
//     });
//
//   setTimeout(function () {
//     addToArray(imagesArray);
//   }, 300);
// }

// $('.js-show-all-images').on('click', function () {
//   imagesPaginationAjax(-1, uploadedImageName, imagesArray);
//   $('.middle-pdf-nav').addClass('hidden');
//   setTimeout(function () {
//     addToArray(imagesArray);
//   }, 300);
// });

//let uploadedImageName = '';
// $('#pdf-form').on('submit', function (e) {
//   imagesArray = [];
//   $('.images-grid').html('');
//   e.preventDefault();
//   $('.upload-progress').addClass('visible');
//   $('.pdf-container .cover-image').addClass('hidden');
//   $('.my-pagination').addClass('hidden');
//   let formData = new FormData(this);
//   formData.append('action', 'pdf_file_upload');
//
//   const options = {
//     method: 'POST',
//     body: formData,
//   };
//
//   fetch(ajax_object.ajax_url, options)
//     .then((response) => response.json())
//     .then((data) => {
//       const $imagesGrid = $('.images-grid');
//       $('.upload-progress').removeClass('visible');
//       $('.my-pagination').removeClass('hidden');
//       $imagesGrid.html(data.html);
//       $('.pdf-container .cover-image')
//         .removeClass('hidden')
//         .find('.cover-image__illustration')
//         .html(data.cover);
//       $imagesGrid.data('total', data.total);
//       $('.js-show-all-images').text('Show All ' + data.total);
//       uploadedImageName = data.folder_name;
//       imagesPagination(
//         $imagesGrid.data('total'),
//         uploadedImageName,
//         imagesArray
//       );
//       nextPDFstep(uploadedImageName);
//     });
// });

// const nextPDFstep = (folder_name) => {
//   $('.next-pdf-step').on('click', function () {
//     $(this).addClass('hidden');
//     $('.middle-pdf-nav').addClass('hidden');
//
//     const formData = new FormData();
//     formData.append('action', 'final_pdf_step');
//     formData.append('folder_name', folder_name);
//     formData.append('selected_images', imagesArray);
//
//     const options = {
//       method: 'POST',
//       body: formData,
//     };
//
//     fetch(ajax_object.ajax_url, options)
//       .then((response) => response.json())
//       .then((data) => {
//         const $pdfContainer = $('.pdf-container');
//         $('.images-grid').html(data.html).addClass('justify-content-center');
//         $('.edition-title, .cover-image, .pdf-form').addClass('hidden');
//         $pdfContainer.prepend(
//           '<p class="text-center text-uppercase">Confirm pages to join into article</p>'
//         );
//         $pdfContainer.append(
//           '<div class="ocr-step-wrapper"><button class="ocr-step">Submit to OCR</button></div>'
//         );
//       });
//   });
// };

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox]', {
    infinite: false,
  });

  $('.submenu-exist').hover(function () {
    $(this).children('.dropdown-submenu').toggleClass('d-none');
  });

  setTimeout(function () {
    $('#um-submit-btn').prop('disabled', true);
  }, 10);

  $('.reset-user-password-form').on('submit', function (e) {
    const $emailInput = $('.um input[type="email"]');
    const value = $emailInput.val().trim();
    const email_regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email_regex.test(value)) {
      $('.um #um-submit-btn').prop('disabled', false);
      $emailInput.removeClass('error');
      $('.email-error').addClass('d-none');
    } else {
      e.preventDefault(); // prevent submit reset form
      $('.um #um-submit-btn').prop('disabled', true);
      $emailInput.addClass('error');
      $('.email-error').removeClass('d-none');
    }
  });

  $('input[name="username_b"]').on('keyup blur focus', function () {
    if ($('input[name="username_b"]').val() !== '') {
      $('#um-submit-btn').prop('disabled', false);
    } else {
      $('#um-submit-btn').prop('disabled', true);
    }
  });

  $('input[name="username-44"], input[name="user_password-44"]').on(
    'keyup blur focus',
    function () {
      if (
        $('input[name="username-44"]').val() !== '' &&
        $('input[name="user_password-44"]').val() !== ''
      ) {
        $('#um-submit-btn').prop('disabled', false);
      } else {
        $('#um-submit-btn').prop('disabled', true);
      }
    }
  );

  /* Custom pagination */
  $(document).on(
    'click',
    '.custom-pagination .custom-pagination__first',
    function () {
      $(this)
        .closest('.custom-pagination')
        .find('input')
        .val(1)
        .trigger('change');
    }
  );

  $(document).on(
    'click',
    '.custom-pagination .custom-pagination__last',
    function () {
      const lastPage = $(this)
        .closest('.custom-pagination')
        .data('total-pages');
      $(this)
        .closest('.custom-pagination')
        .find('input')
        .val(lastPage)
        .trigger('change');
    }
  );

  $(document).on(
    'click',
    '.custom-pagination .custom-pagination__prev',
    function () {
      const currentValue = $(this)
        .closest('.custom-pagination')
        .find('input')
        .val();
      if (Number(currentValue) - 1 >= 1) {
        $(this)
          .closest('.custom-pagination')
          .find('input')
          .val(Number(currentValue) - 1)
          .trigger('change');
      }
    }
  );

  $(document).on(
    'click',
    '.custom-pagination .custom-pagination__next',
    function () {
      const lastPage = $(this)
        .closest('.custom-pagination')
        .data('total-pages');
      const currentValue = $(this)
        .closest('.custom-pagination')
        .find('input')
        .val();
      if (Number(currentValue) + 1 <= lastPage) {
        $(this)
          .closest('.custom-pagination')
          .find('input')
          .val(Number(currentValue) + 1)
          .trigger('change');
      }
    }
  );

  /* Custom dropdown */
  $(document).on('click', '.custom-select', function () {
    $(this).toggleClass('opened');
    event.stopPropagation();
  });

  $(document).on('click', function (event) {
    if (!$(event.target).closest('.custom-select').length) {
      $('.custom-select').removeClass('opened');
    }
  });

  $(document).ready(function () {
    $('#username-44').removeAttr('placeholder');
    $('#username-44').attr('type', 'email');
    $('#username-44').prop('required', true);
    $(
      '<p class="d-none email-error text-danger">Please enter a valid email.</p>'
    ).insertAfter('.um input[type="email"]');

    $('.um input[type="email"]').on('blur', function () {
      const value = $(this).val().trim();
      const email_regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (email_regex.test(value)) {
        $('.um #um-submit-btn').prop('disabled', false);
        $(this).removeClass('error');
        $('.email-error').addClass('d-none');
      } else {
        $('.um #um-submit-btn').prop('disabled', true);
        $(this).addClass('error');
        $('.email-error').removeClass('d-none');
      }
    });

    $(
      '<p class="d-none password-error text-danger">Please enter valid password.</p>'
    ).insertAfter('.um #user_password-44');

    $('.um input[name="user_password-44"]').on('blur', function () {
      const value = $(this).val().trim();
      if (value.length) {
        $('.um #um-submit-btn').prop('disabled', false);
        $(this).removeClass('error');
        $('.password-error').addClass('d-none');
      } else {
        $('.um #um-submit-btn').prop('disabled', true);
        $(this).addClass('error');
        $('.password-error').removeClass('d-none');
      }
    });

    $('input[name="add_workspace_name"]').on('blur', function () {
      const value = $(this).val().trim();
      const regex = /^[a-zA-Z0-9\- ]+$/;
      if (regex.test(value)) {
        $(this).removeClass('error');
        $('input[name="workspace-add"]').prop('disabled', false);
      } else {
        $(this).addClass('error');
        $('input[name="workspace-add"]').prop('disabled', true);
      }
    });

    $('input[name="workspace_name"]').on('blur', function () {
      const value = $(this).val().trim();
      const regex = /^[a-zA-Z0-9\- ]+$/;
      if (regex.test(value)) {
        $(this).removeClass('error');
        $('input[name="workspace-update"]').prop('disabled', false);
      } else {
        $(this).addClass('error');
        $('input[name="workspace-update"]').prop('disabled', true);
      }
    });
  });

  $(document).on('click', '.custom-select__item', function () {
    const value = $(this).data('label');
    $(this).closest('.custom-select').find('span').html(value);
    $(this).siblings().removeClass('selected');
    $(this).addClass('selected');
  });

  /* Check if Apple device using */
  const expression = /(Mac|iPhone|iPod|iPad)/i;
  if (expression.test(navigator.userAgent)) {
    $('body').addClass('apple-device');
  }
});

$(window).on('load', function () {
  pageNavHeight();
});
